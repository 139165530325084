@font-face {
    font-family: 'GunPlay';
    src: url('./fonts/GUNPLAY.ttf') format('truetype');
}

th {
    font-size: 0.8em;
}

th.sortable:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
}

a.nav-link:hover {
    text-decoration: underline;
}

a.navbar-brand:hover {
    opacity: 0.8;
}

.spinner-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.loadingMessage {
    padding: 20px;
}

.pagination {
    justify-content: center;
    overflow: hidden;
}

.page-item {
    width: 4.5rem;
    text-align: center;
}

.page-item:hover {
    text-decoration: underline;
    opacity: 0.8;
    cursor: pointer;
}

body {
    min-height: 100vh;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 0.8em;
    z-index: 2;
}

.footer-text {
    line-height: 2.0rem;
}

.container {
    width: 100%;
    margin-bottom: 4rem;
}

span.date {
    font-size: 0.75em;
}

.accordion-button:not(.collapsed) {
    --bs-accordion-active-bg: #151414;
}

#app {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 2.5rem;
    overflow: hidden;
    min-height: 95vh !important;
}

.search-input {
    z-index: 1000;
    border-width: 1px;
    border: none;
}

.dropdown-menu {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 2000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    background-color: #444;
    color: #FFFFFF;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border-radius: 0 0 10px 10px;
    top: 2rem;
    border: none;
}

.dd-name {
    border: 2px solid var(--bs-dropdown-border-color);
    background-color: var(--bs-body-bg);
}

.form-control:focus {
    box-shadow: 0 0 0 0 rgba(234,57,184,.25)
}

.search-item {
    color: #FFFFFF;
}

.search-item:hover {
    background-color: #5f5f5f;
}

.carousel-container {
    width: 100%;
    padding-bottom: 25%; /* this sets the aspect ratio to 16:9 */
    position: relative;
    overflow: clip;
}
  
.carousel-container .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.center {
    text-align: center;
}

a.url {
    color: #ea39b8 !important;
}

.list-group-horizontal {
    flex-wrap: wrap;
    max-width: 100%;
}

.list-group-item {
    flex-basis: 50%;
}

p.small {
    font-size: 0.8em;
    font-style: italic;
}

.active {
    color: #00bc8c;
}

.discordButton {
    width: 8rem;
}

h4.about {
    margin-top: 2rem;
}

code.stat {
    padding-left: 0.3rem;
    font-family: 'Courier New', Courier, monospace;
}

a.backbtn {
   color: #2a4c77 !important; 
}

a.backbtn:hover {
    opacity: 0.7;
    cursor: pointer;
}

.charts-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
}
  
.charts-container > * {
    flex-basis: 20rem; 
}

.chart-wrapper {
    text-align: center;
    width: 100%;
}

.table-wrapper {
    overflow-x: auto;
    margin-bottom: 0.5rem;
}

.pink {
    color: var(--bs-code-color);
}

.match-block {
    border: 1px solid #000;
    background-color: #250d49;
    border-radius: 30px 5px 5px 30px; 
    font-size: 0.9em;
}

.link-no-shadow {
    text-shadow: none;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgba(0, 0, 0, 0.15);
}

.tooltip-inner {
    max-width: 400px;
}

.dropdown-menu.show {
    z-index: 1001;
}

.stat-table-container {
    background-color: var(--bs-body-bg);
    border-radius: 10px;
}

.map-table-container {
    background-color: #250d49;
    border-radius: 5px;
    overflow-x: auto;
}

.stat-table {
    margin: auto;
    width: auto;
}

.stat-cell {
    padding: 0.5em;
}

.inner-stat-cell {
    padding-left: 2em;
}

.player-card-header {
    background-image: linear-gradient(#061b35 0,#091c33 7%,#172b45 80%,#21344c 100%);
}

.top-rounded {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.map-stat-table {
    margin-bottom: 0;
}

.map-cell {
    padding: 0.5em;
    padding-left: 1em;
}

code.map {
    padding: 0;
    font-size: 0.7em;
}

a.name-history {
    font-size: 1.5em;
    font-weight: bold;
}

a.name-history:hover {
    cursor: pointer;
    opacity: 0.8;
}

.name-history-dropdown {
    width: 18rem;
}

.name-history-item {
    color: #FFF;
}

.name-history-header {
    font-size: 0.8em;
    color: #c3e30e;
}

.page-link {
    position: relative;
    display: block;
    text-decoration: none;
    color: #FFF;
    border: 1px solid #161616 !important;
}

.page-link:hover {
    z-index: 2;
    border-color: #5c83b3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    color: #000000;
}

.page-link-loading {
    cursor: wait;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.stat-block {
    flex: 1;
    border: none;
    height: 8.5em;
    text-align: center;
    vertical-align: top;
    background-color: var(--bs-body-bg);
}

.stat-block-sm {
    flex: 1;
    border: none;
    text-align: center;
    vertical-align: top;
    margin-top: -1.1rem;
    background-color: var(--bs-body-bg);
}

.stat-value {
    font-size: 0.9rem;
    color: var(--bs-light);
    font-weight: bolder;
}

.stat-value-link {
    font-size: 0.9rem;
    font-weight: bolder;
}

.stat-container {
    display: flex;
    justify-content: center;
}

.stat-container-flex {
    display: flex;
    justify-content: center;
}

.session-logo {
    width: 3rem;
}

.session-name {
    vertical-align: middle;
    text-align: left;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 15vw;
}

.session-date {
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    width: 25%;
    text-wrap: nowrap;
}

.session-link {
    font-weight: bold;
    font-size: 1.0rem;
}

.title {
    color: var(--bs-code-color);
    font-weight: 500;
}

.disable-shadow {
    text-shadow: none !important;
}

.home-container {
    display: flex;
    flex-direction: row;
}

.servers-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.home-block {
    flex: 1;
}

.server-block {
    flex: 1;
    background-color: var(--bs-body-bg);
    border-radius: 10px;
    max-height: 15rem;
    min-height: 13rem;
    overflow: hidden;
    font-size: 0.85em;
}

.inner-server-block {
    flex: 1 1;
}

.inner-server-block-right {
    flex: 1 1;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.block-wrapper {
    flex: 1;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    display: flex;
}

.title-image {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.ellipsis-overflow-block {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.online-leaderboard {
    max-width: 0.5rem;
    vertical-align: middle;
}

@media (max-width: 1050px) {
    .home-container {
        display: flex;
        flex-direction: column;
    }

    .opfor-img {
        display: none;
    }

    .ml-1r {
        margin-left: 0 !important;
    }

    .profile-flex {
        flex-direction: column !important;
    }

    .profile-stats-flex {
        width: 100%;
    }

    .profile-flex-charts {
        width: 100% !important;
        margin-top: 1rem !important;
    }

    .chart-wrapper {
        width: 50% !important;
    }

    .servers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .server-block {
        min-width: 26em;
        max-width: 26em;
    }

    .online-leaderboard {
        max-width: 2.0rem;
    }

    .match-history {
        min-height: 5rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .home-block {
        margin-top: 1.5rem;
    }

    .session-date {
        display: none;
    }

    .session-link {
        font-size: 0.7em;
    }

    .stat-container {
        display: inline;
        justify-content: center;
    }

    .pagination {
        justify-content: center;
        overflow: hidden;
        --bs-pagination-padding-x: 0rem;
    }

    .server-block {
        max-height: none;
    }
    
    .carousel-container {
        height: 22rem;
    }

    .carousel-item img {
        width: 200% !important;
        height: 22rem;
        transform: scaleX(1.1); /* Scale the image horizontally by 2.5 times */
        transform-origin: right; /* Set the origin to the right side */
    }

    .intro-message {
        display: none;
    }

    .chart-wrapper {
        width: 75% !important;
    }

    .page-header {
        margin-left: 1.5rem;
    }
}

@media only screen and (max-width: 580px) {
    .carousel-item img {
        width: 200% !important;
        height: 20rem !important;
        transform: scaleX(1.2) !important;
        transform-origin: right center !important;
    }
}

@media only screen and (max-width: 520px) {
    .player-name {
        font-size: 2rem !important;
    }
    
    .pbb {
        display: inline-block !important;
    }

    .back-profile {
        margin-bottom: 1rem !important;
    }
    
    .carousel-container {
        padding-bottom: 28% !important;
    }
}

@media only screen and (max-width: 418px) {
    .player-name {
        font-size: 1.7rem !important;
    }
    
    .back-profile {
        display: none !important;
    }

    .chart-wrapper {
        width: 85% !important;
    }

    .carousel-item img {
        width: 200% !important;
        height: 22rem !important;
        transform: scaleX(1.3) !important;
        transform-origin: right center !important;
    }
}

.carousel-item img {
    width: 100%; /* Ensure the image fills the container width */
    height: auto; /* Allow the height to adjust based on the image aspect ratio */
}

.heading {
    font-size: 1.6em;
    font-weight: bold;
}

.name-heading {
    font-weight: bolder;
    font-size: 1.8em;
}

.sub-heading {
    font-size: 1em;
    font-weight: bold;
}

.active>.page-link, .page-link.active {
    background-color: #242424;
    color: #FFF !important;
}

.color {
    color: #3498db;
}

.overflow {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.glow {
    text-shadow: 0 0 1px rgba(255,255,255,.2), 0 0 2px rgba(255,255,255,.1), 0 0 5px rgba(255,255,255,0)
}

.overlay-content {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 0;
    text-align: center;
    z-index: 5;
}

.online-circle {
    height: 1.25em;
    width: 1.25em;
    margin-left: 10px;
    background-image: linear-gradient(#68ef61, #4bdd43 7%, #20671c 80%, #194e16);
    border-radius: 50%;
    display: inline-block;
    animation: pulse 2s infinite;
    border: 1.5px solid #232222;
}

.offline-circle {
    height: 1.25em;
    width: 1.25em;
    margin-left: 10px;
    background-image: linear-gradient(#ef6161, #dd4343 7%, #671c1c 80%, #4e1616);
    border-radius: 50%;
    display: inline-block;
    border: 1.5px solid #232222;
}

.status-toast {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

#intro {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 100%;
}

#intro-welcome {
    display: flex;
}

#intro-buttons {
    display: flex;
    flex-direction: row;
}

.body-bg {
    background-color: var(--bs-body-bg);
}

.ml-1r {
    margin-left: 1rem;
}

.ml-02 {
    margin-left: 0.4rem;
}

.carousel-indicators {
    display: none;
}

.opfor-img {
    position: absolute;
    height: 45rem;
    left: -3rem;
    bottom: -3rem;
    z-index: 1;
}

.font-weight-bolder {
    font-weight: bolder;
}

.container {
    margin-top: 1.5rem;
}

.profile-flex {
    flex-direction: row;
}

.profile-sub-heading {
    font-size: 1.4rem;
}

.player-name {
    font-family: 'GunPlay', sans-serif;
    color: #c3e30e;
    font-size: 2.5rem;
}

.yellow {
    color: #c3e30e;
}

.gunplay {
    font-family: 'GunPlay', sans-serif;
}

.page-header {
    font-size: 2rem;
}

.match-history {
    min-height: 25rem;
}

.map-filter {
    width: 40rem;
}

tr {
    font-size: 1em;
    line-height: 2.5em;
}

th {
    line-height: 1em !important;
}